<template>
  <div class="Storyboard">
    <v-row class="justify-center">
      <v-col xs="12"> 
        <v-card class="d-flex flex-column mt-8 mx-auto">
        <v-card-title class="justify-center"
          >Workshop Results</v-card-title>
        <v-card-text class="black--text">
          <p> 
            We conducted a workshop with 25 participants to evaluate our design space. 
            Below are the stoyboards the participants created by using our design space.
          </p>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
      <v-row class="mx-auto justify-center">
      <v-col xs="12" sm="6" md="6" lg="6" xl="4" v-for="board in storyboard" :key="board.id">
       <!-- <div v-for="video in gallery" :key="video.name"> -->
         <v-card class="my-2 d-flex flex-column pt-0">
            <v-img
                :src='"../assets/image/storyboard/"+ board.imagePosition + ".png"'
                style="margin-top:0px !important"
              ></v-img>
              <!-- <v-spacer></v-spacer> -->
              <v-card-text style="padding: 10px 2px 2px 10px !important">
                <div height="100px" class="black--text font-weight-bold name" style="line-height:1rem">{{board.name}}</div>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text style="padding: 2px 2px 2px 10px !important">
                <div class="black--text author">From: {{board.author}}</div>
                </v-card-text>
         </v-card>
      </v-col>
      </v-row>
        <!-- </div> -->
  </div>
</template>
<script>
import storyboard from "../assets/storyboard.json";

export default({
  name: "storyboard",
  data (){
    return {
      storyboard:storyboard
      }
  }
})
</script>
<style>
.author{
  font-size:0.6rem !important;
  margin-bottom:3px !important;
}
.name{
  font-size:0.7rem !important;
  margin-bottom:3px !important;
}

</style>