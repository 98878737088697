<template>
  <v-app class theme--yellow>
    <NavBar/>
    <v-main :class="episode+'-bg'">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import { mapState } from "vuex";

export default {
  name: 'App',
  components: {
    NavBar,
  },
  computed: {
    ...mapState({
      episode: (state) => state.thisEpisode,
    }),
  },
  data: () => ({
    //
  }),
};
</script>

<style>
/* .v-application--wrap{
  background-image: url("./assets/image/mem-background1.png");
  background-repeat:repeat;
  background-color:#F4F7F6
} */
.v-application--wrap::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.setting-bg{
    background-color: white;
    background-image: url("./assets/image/setting-bg.png") !important;
    background-size:cover;
    background-repeat: no-repeat;
}
.rising-bg{
    background-color: white;
    background-image: url("./assets/image/rising-bg.png") !important;
    background-size:cover;
    background-repeat: no-repeat;
}
.resolution-bg{
    background-color: white;
    background-image: url("./assets/image/resolution-bg.png") !important;
    background-size:cover;
    background-repeat: no-repeat;
}
</style>