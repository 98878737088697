<template>
  <nav>
    <v-app-bar
      :class="' navBar ' + episode + '-background topnav'"
      style="z-index: 20"
      app
      clipped-left
    >
      <v-toolbar-title class="barTitle">Freytag's Design Space</v-toolbar-title>
      <!-- <v-spacer></v-spacer> -->
      <v-btn
        active-class="activePage"
        class="inactivePage"
        text
        router
        to="/"
        style="margin-left: 8vw"
      >
        Home
      </v-btn>
      <v-btn
        active-class="activePage"
        class="inactivePage"
        @click="changeNav"
        text
        router
        to="Gallery"
        style="margin-left: 4vw"
        >Gallery</v-btn
      >
      <v-btn
        active-class="activePage"
        class="inactivePage"
        @click="changeNav"
        text
        router
        to="Storyboard"
        style="margin-left: 4vw"
      >
        Storyboard
      </v-btn>
      <v-btn
        active-class="activePage"
        class="inactivePage"
        @click="changeNav"
        text
        router
        to="Download"
        style="margin-left: 4vw"
      >
        Download
      </v-btn>
      <v-btn
        active-class="activePage"
        class="inactivePage"
        @click="changeNav"
        text
        router
        to="About"
        style="margin-left: 4vw"
      >
        About
      </v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      episode: (state) => state.thisEpisode,
    }),
  },
  data() {
    return {
      menu: [
        { name: "Home", link: "/" },
        { name: "Gallery", link: "Gallery" },
        { name: "Storyboard", link: "Storyboard" },
        { name: "Download", link: "Download" },
        { name: "About", link: "About" }
      ]
    };
  },
  methods: {
    changeNav() {
      this.$store.commit("changeEpisode", "setting");
    },
    myFunction() {
      let x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
  },
};
</script>
<style>
.barTitle {
  font-size: 1rem !important;
  font-weight: bold;
}
.activePage {
  border-bottom: 3px solid white !important;
  border-radius: 0px !important;
}
.inactivePage {
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-radius: 0px !important;
}
.setting-background {
  background-color: #f3df7d !important;
  background-image: url("../assets/image/setting-background.png") !important;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.rising-background {
  background-color: #f7a2a1 !important;
  background-image: url("../assets/image/rising-background.png") !important;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.resolution-background {
  background-color: #7ecfd4 !important;
  background-image: url("../assets/image/resolution-background.png") !important;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>